import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  AllStudent,
  CertificateStudent,
  CheckStudentsMilitaryRegistrationDTO,
  Student,
  StudentFilter,
  StudentName
} from '../../models/contingent/student.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.student}`;

  constructor(private http: HttpClient) { }

  //Get all student
  public getAllstudent(): Observable<Student[]> {
    return this.http.get<Student[]>(this.baseUrl);
  }

  //Get By Id student
  getByIdstudent(id: Guid | string): Observable<Student> {
    return this.http.get<Student>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  getCertificateStudent(id: Guid | string): Observable<CertificateStudent> {
    return this.http.get<CertificateStudent>(this.baseUrl + '/GetCertificateStudentById/' + id)
      .pipe(catchError(this.handleError));
  }


  //Add student
  addstudent(student: Student): Observable<Student> {
    return this.http.post<Student>(this.baseUrl, student)
      .pipe(catchError(this.handleError));
  }

  //Delete student
  deletestudent(id: any): Observable<Student> {
    return this.http.delete<Student>(this.baseUrl + '/DeleteStudent/' + id)
      .pipe(catchError(this.handleError));
  }

  deleteAllStudents(id: any): Observable<Student> {
    return this.http.delete<Student>(this.baseUrl + '/DeleteStudPersonStudents/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit student
  updatestudent(student: Student): Observable<Student> {
    return this.http.put<Student>(this.baseUrl + '/' + student.studentId, student)
      .pipe(catchError(this.handleError));
  }

  getStudentNameByIds(students: (string|undefined)[]): Observable<StudentName[]> {
    return this.http.post<StudentName[]>(this.baseUrl + '/GetJournalStudentName', students)
      .pipe(catchError(this.handleError));
  }

  getAllStudents(filter?: StudentFilter) {
    return this.http.post<AllStudent[]>(`${this.baseUrl}/GetAllStudent`, filter ?? {})
      .pipe(catchError(this.handleError));
  }
  checkStudentsMilitaryRegistration(studentIds : Guid[]) : Observable<CheckStudentsMilitaryRegistrationDTO> {
    return this.http.post<CheckStudentsMilitaryRegistrationDTO>(this.baseUrl + '/CheckStudentsMilitaryRegistration', studentIds)
      .pipe(catchError(this.handleError));
  }
  getNewStudentNumber(dictTrainingLevelId: string|undefined, facultyId: string|null, yearAdmission: number): Observable<string> {
    return this.http
      .post(this.baseUrl + '/' + 'GetNewStudentNumber', {
        dictTrainingLevelId: dictTrainingLevelId,
        facultyId: facultyId,
        yearAdmission: yearAdmission,
      }, {responseType: 'text'})
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}
