enum MultiSelectEnumList {
  budget = 1,
  academicState = 2,
  citizenship = 3,
  trainingLevel = 4,
  organization,
  department,
  scientificPerson,
  post,
  filial,
  budgetCategory
}

export var MultiSelectEnum: any = MultiSelectEnumList
