import { Guid } from "guid-typescript";
import { EduGroup } from "./edugroup.model";
import { Student } from "./student.model";

export class StudEduGroup{
  public studEduGroupId!: number;
  public studentId?: Guid;
  public student!: Student;
  public eduGroupId?: Guid;
  public eduGroups?: EduGroup;

  public courseNum?: number;
}

export class AcademicGroupStudents{
  public index?:number;
  public studentId?:Guid;
  public studPersonId?:Guid;
  public fio?:string;
  public planId?:Guid;
  public planName?:string;
  public standardName?:string;
  public programName?:string;
  public studyFormName?:string;
  public academicState?:string;
  public academicStateCategory?:number;
}

export class GroupHistory{
  public name!: string;
  public externalId?: string;
  public studPlanId?: Guid;
  public studEduGroupId?: Guid;
  public dateStart?: Date;
  public dateFinish?: Date;
  public planStart?: Date;
  public planFinish?: Date;
  public planId?: Guid;
  public groupPlanId?: Guid;
}
