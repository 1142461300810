export namespace EducationPlanDetailsModels {
  export interface EPlan {
    educationPlanId: string;
    id?: string;
    longName: string;
    shortName: string;
    name: string;
    yearAdmission: number;
    acceleratedSign: boolean;
    dictAcceleratedStudyBaseId: string;
    dictStudyTechnologyId: string;
    dictStudyFormId: string;
    studyFormId: string;
    studyFormShortName: string;
    studyFormName: string;
    studyFormOrderNum?: number;
    status: number;
    createdAt: Date;
    description: string;
    studyTechnologyId: string;
    studyTechnologyName: string;
    developmentPeriodYears: number;
    developmentPeriodMonths: number;
    protocolNumber?: string;
    protocolDate?: Date;
    professionalActivities: string;
    semesterDuration: EPlanSemester[];
    educationProgramId?: string;
    facultyId: string;
    facultyName: string;
    facultyShortName: string;
    kafedraId: string;
    kafedraName: string;
    dictStandardTypeId: string;
    dictTrainingLevelId: string;
    filialId: string;
  }

  export interface ECopyPlan {
    id: string;
    standard: string;
    educationProgramCipher: string;
    educationProgramName: string;
    studyFormName: string;
    educationPlanName: string;
    laboriousness: number;
    status: number;
  }

  export interface EProgram {
    educationProgramId: string;
    educationProgramCipher: string;
    educationProgramName: string;
    graduatingDepartmentId: string;
    graduatingDepartmentName: string;
    qualificationId: string;
    qualificationName: string;
    industrialPartner: string;
    facultyId: string;
    filialId: string;
    educationPlans: EPlan[];
    educationProgramNameWithCipher?: string;
  }

  export interface EStandard {
    educationStandardId: string;
    directionTraining: string;
    educationStandardCipher: string;
    educationStandardName: string;
    educationLevelId: string;
    educationLevelName: string;
    standardTypeId: string;
    standardTypeName: string;
    laboriousness: number;
    number: string;
    dateFGOS: Date;
    educationPrograms: EProgram[];
    trainingLevelId: string;
    trainingLevelName: string | null;
    printStandardTitle: string;
    printProfileTitle: string;
  }

  export interface EPlanSummary {
    errorList: string[];
    studentSummary: {
      rows: EStudentSummaryRow[];
    };
    hoursSummary: EHoursSummary[];
  }
  export interface EStudentSummaryRow {
    name: string;
    semesters: any[];
    total: number | undefined;
  }
  export interface EHoursSummary {
    level: number;
    name: string;
    value: number;
  }

  export interface EDiscipline {
    id: string;
    cipher: string;
    name: string;
    totalHours: number;
    totalLaborIntensity: number;
    hoursCredited: number;
    control: number;
    departmentName: string;
    semesterName: string;
    typeWorks: ETypeWork[];
    components: EDiscipline[];
    disciplines: EDiscipline[];
    subDisciplines: EDiscipline[];
    level?: number;
    dictCycleId?: string;
    dictComponentId?: string;
    isModularDiscipline?: boolean;
  }

  export interface EPlanDiscipline {
    level: number;
    cycleId: string;
    optionalMarks: boolean;
    componentId: string;
    id: string;
    isModularDiscipline: true;
    cipher: string;
    name: string;
    departmentName: string;
    semesterName: string;
    totalHours: number;
    totalLaborIntensity: number;
    hoursCredited: number;
    control: number;
    hoursContactWork: number;
    hoursSelfWork: number;
    typeWorks: ETypeWork[];
    subDisciplines: EDiscipline[];
    semesters: number[];
    serialNumber: number;
    active: boolean;
  }

  export interface ETypeWork {
    id: number;
    shortName: string;
    value: number;
  }

  export interface EPlanDiscipline {
    id: string;
    educationPlanId: string;
    dictDisciplineTypeId: string;
    dictDisciplineId: string;
    dictDisciplineName: string;
    dictCycleId: string;
    dictComponentId: string;
    dictDepartmentId: string;
    serialNumber: number;
    isModularDiscipline: true;
    parentDisciplineId: string;
    totalLaborIntensity: number;
    totalNumberHours: number;
    cipher: string;
    trainingLevelInternalCode: number;
    controllingActions: ControllingAction[];
    electiveDisciplines: ElectiveDiscipline[];
    typeWorksDisciplineAdditionals: {
      dictTypeWorkDisciplineAdditionalId: string;
      typeWorksAdditionals: TypeWork[];
    }[];
    active: boolean;
  }

  export interface ControllingAction {
    id: string;
    dictControlActionId: string;
    semesterId: string;
    dictLessonTypeId?: string;
    dictLessonFormId: string;
    hoursCredited: number;
    hoursSelfWork: number;
    hoursContactWork: number;
    control: number;
    isDistributed: boolean;
    totalHours: number;
    totalLaborIntensity: number;
    hasCourseProject: boolean;
    hasCourseWork: boolean;
    typeWorks: TypeWork[];
  }

  export interface ElectiveDiscipline {
    id: string;
    dictDisciplineId: string;
    dictDisciplineName: string;
    departmentId: string;
    serialNumber: number;
    active: boolean;
    semesterWorkElectives: SemesterWorkElective[];
  }

  export interface SemesterWorkElective {
    semesterId: string;
    typeWorkElectives: TypeWork[];
  }

  export interface TypeWork {
    dictWorkStudyPlanTypeId: string;
    filialId: string;
    value: number;
  }

  export interface EPlanSemester {
    id: string;
    number: number;
    courseNumber: number;
    semesterDurationOrNumberSessions: number;
    controlAction?: Partial<ControllingAction>;
  }
}

export interface EducationPlanChangeStatus {
  educationPlanId: string;
  status: number;
  description: string;
}

export interface EPlanSemesterDurationDto {
  id: number | string;
  number: number;
  courseNumber: number;
  semesterDurationOrNumberSessions: number;
}

export class ReattachPlanDataSources {
  trainingLevels!: {trainingLevelId: string | null, trainingLevelName: string}[];
  qualifications!: {qualificationId: string, qualificationName: string}[];
  faculties!: {facultyShortName: string}[]
}

export interface ReattachPlan {
  educationPlanId: string;
  educationProgramId: string;
}
