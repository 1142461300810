import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {
  process,
  GroupDescriptor,
  State,
  SortDescriptor,
  orderBy,
  GroupResult, groupBy
} from '@progress/kendo-data-query';
import {StudplanService} from "../../../services/contingent/studplan.service";
import {
  EducationPlanControllingAction,
  EducationPlanDiscipline,
  StudentEducationPlan
} from "../../../models/contingent/educationplandiscipline.model";
import {StudentmarkService} from "../../../services/contingent/studentmark.service";
import {CurrentControlMark, StudentMark, StudentMarkRequest} from "../../../models/contingent/studentmark.model";
import {RowClassArgs} from "@progress/kendo-angular-grid";
import {DisciplineWorkTypeEnum} from "../../../models/contingent/enums/discipline-work-type.enum";
import {CommonStatusEnum} from "../../../models/dicts/enums/common-status.enum";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {DateFromUTCAsLocal, TimeZoneFix} from "../../../helpers/date-helper";
import {OrdersstudyperiodService} from "../../../services/contingent/ordersstudyperiod.service";
import {OrdersStudyPeriod} from "../../../models/contingent/ordersstudyperiod.model";
import {StudentmaininfoComponent} from "../studentmaininfo/studentmaininfo.component";
import {Guid} from "guid-typescript";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {EducationService} from "../../../services/contingent/education.service";
import {StudentEducationPlanList} from "../../../models/contingent/educationplan.model";
import {StudPlan} from "../../../models/contingent/studplan.model";
import {StudentsemestrService} from "../../../services/contingent/studentsemestr.service";
import {StudentSemesterEditDto, StudentSemestr} from "../../../models/contingent/studentsemestr.model";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {
  ElectiveDisciplineCommonStatusEnum
} from "../../../models/contingent/enums/elective-discipline-commonstatus.enum";
import {StudedugroupService} from "../../../services/contingent/studedugroup.service";
import {GroupHistory} from "../../../models/contingent/studedugroup.model";
import {AcademicProgressTabsEnum} from "../../../models/contingent/enums/academic-progress-tabs.enum"

@Component({
  selector: 'app-academicprogress',
  templateUrl: './academicprogress.component.html',
  styleUrls: ['./academicprogress.component.scss']
})
export class AcademicprogressComponent implements OnInit {

  public loading = false;
  public termsEdit: boolean = false
  public trimester: boolean = false
  public currentGroup: boolean = true
  public studentId!: Guid;
  public totalData: any[] = [];
  public semesterData: any[] = [];
  public totalColumns: any[] = [];
  public studentSemesters: StudentSemestr[] = []
  public group:GroupDescriptor[] = [{ field: "semesterNumber" }];
  public groupSemester: GroupDescriptor[] = [{ field: "courseNum" }];
  public gridViewMark: GroupResult[] | StudentMark[] =[];
  public gridViewCurrentMark: GroupResult[] | StudentMark[] =[];
  public type: any = DisciplineWorkTypeEnum;
  public commonStatus: any = CommonStatusEnum;
  public orderStudyPeriod?: OrdersStudyPeriod = {}
  public semestersToSave: StudentSemesterEditDto[] = []
  public groupHistory: GroupHistory[] = []
  public planName: string = ""
  public TabsEnum = AcademicProgressTabsEnum;
  public initialTab = AcademicProgressTabsEnum.MiddleControl;

  constructor(private notificationService: NotificationsService,
              private router: Router,
              private activateRoute: ActivatedRoute,
              private studPlanService: StudplanService,
              private studentMarkService: StudentmarkService,
              private orderStudyPeriodService:OrdersstudyperiodService,
              public studentMainInfoComponent:StudentmaininfoComponent,
              private educationService: EducationService,
              private semesterService: StudentsemestrService,
              private studEduGroupService: StudedugroupService)
  {
    if(activateRoute.snapshot.parent!==null) this.studentId = activateRoute.snapshot.parent.params["studentId"];
    if(activateRoute.snapshot.url.toString() === "progress,currentControl") this.initialTab = AcademicProgressTabsEnum.CurrentControl

  }

  public educationPlan: StudentEducationPlan = {jsonData:""}
  public studPlan: StudPlan = {}
  public planId?: string;
  public historyId?: string;
  public groupChangeable = false;
  public studentMarks: StudentMark[] = []
  public currentControlMarks: CurrentControlMark[] = []
  public educationPlanList: StudentEducationPlanList[] = []

  public aggregates: any[] = [{ field: 'TotalNumberHours', aggregate: 'sum' }, { field: 'TotalLaborIntensity', aggregate: 'sum' }, { field: 'DictDisciplineName', aggregate: 'count' }];

  public state: State = {
    group: [{ field: 'DictCycleName', aggregates: this.aggregates }, { field: 'DictComponentName', aggregates: this.aggregates }],
  };

  public bupData: EducationPlanDiscipline[] = [];
  public allBupData: EducationPlanDiscipline[] = [];
  public controlAction: EducationPlanControllingAction[] = [];
  public semesters?: any = [];
  public gridData: any = process(this.bupData, this.state);

  async ngOnInit() {
    this.getStudentGroupHistory()
    await this.getEducationPlanDiscipline()
    this.getAcademicProgress({studentId: this.studentId})
    await this.getCurrentControl({studentId: this.studentId})
    this.getAllOrderStudyPeriod()
    this.getEducationPlanByStudent()
    this.getStudPlan()
    await this.getStudentSemesters()
  }

  public getAcademicProgress(request: StudentMarkRequest): void {
    this.loading = true;
    this.studentMarkService.getStudentMark(request).subscribe({
        next: (response) => {
        this.studentMarks = response;
        if (this.allBupData.length > 0) this.getDiscipline();
        this.gridViewMark = groupBy(orderBy(this.studentMarks, this.sort), this.group);
        this.loading = false;
        },
      error: () => {
        this.loading = false;
      },
      });
  }

  public async getCurrentControl(request: StudentMarkRequest) {
    this.loading = true;
    (await this.studentMarkService.getCurrentControlMark(request))
      .subscribe({
        next: (response) => {
          this.currentControlMarks = []
          this.currentControlMarks = response
          this.loadItems()
        },
        error: () => {}
      });
  }

  public getAllOrderStudyPeriod() {
    this.orderStudyPeriodService.getStudentStudyPeriod(this.studentId)
      .subscribe(
        response => {
          this.orderStudyPeriod = response;//.find((x) => x.studentId == this.studentId);
          this.editable=this.orderStudyPeriod.editable!
          if (this.orderStudyPeriod != undefined){
            if (this.orderStudyPeriod.dateStart)this.orderStudyPeriod.dateStart = DateFromUTCAsLocal(this.orderStudyPeriod.dateStart);
            if (this.orderStudyPeriod.dateFinish)this.orderStudyPeriod.dateFinish = DateFromUTCAsLocal(this.orderStudyPeriod.dateFinish);
            this.formStudyPeriod.reset(this.orderStudyPeriod);
            this.studentMainInfoComponent.contingent.ordersStudyPeriod=this.formStudyPeriod.value;
          }
          else{
            this.formStudyPeriod.reset();
          }
        }
      );
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public editable : boolean = false

  public sort: SortDescriptor[] = [
    {
      field: "semesterNumber",
      dir: "desc",
    },
    {
      field: "controlActionName",
      dir: "asc",
    },
    {
      field: "discipline",
      dir: "asc",
    },
  ];
  public sortSemester: SortDescriptor[] = [
    {
      field: "semesterNum",
      dir: "asc",
    },
  ];

  public getDiscipline(){
    let sessionMarks: string[] = [];
    for (let semester = 1; semester <= (this.studentSemesters.length != 0
      ? new Date().getMonth() >= 2 && new Date().getMonth() < 8 || this.groupHistory[0]?.externalId != this.historyId
        ? this.studentSemesters[this.studentSemesters.length - 1].semesterNum
        : this.studentSemesters[this.studentSemesters.length - 2].semesterNum
      : this.educationPlan.semesterNum!); semester++)
    {
      let disciplines = this.allBupData.filter((x:any)=>x.ControllingActions.find((y:any)=>y.SemesterNumber===semester)!=null)
      for(let i = 0; i < disciplines.length; i++){
        for(let j = 0; j < disciplines[i].ControllingActions.length; j++) {
          //Проверяем семестр контрольной акции и исключаем дисциплины Еез контрольной акции
          if(disciplines[i].ControllingActions[j].SemesterNumber === semester && disciplines[i].ControllingActions[j].DictControlActionSName !== this.type.wcs) {
            let mark = this.studentMarks.find((x) => x.semesterNumber === semester && x.disciplineId === disciplines[i].ControllingActions[j].Id
              && x.controlActionName === disciplines[i].ControllingActions[j].DictControlActionName)

            let markCurrent = this.currentControlMarks.find((x) => x.semesterNumber === semester && x.disciplineId === disciplines[i].ControllingActions[j].Id
              && x.controlActionName === disciplines[i].ControllingActions[j].DictControlActionSName)

            if (mark)
              sessionMarks.push(mark.externalId!);

            //Добавление дисциплины из плана, в случае отсутсвия оценки
            if (mark === undefined)
            {
              this.studentMarks.push({
                disciplineName: (disciplines[i].ElectiveDisciplines.length > 0
                  //Вывод элективных дисциплин
                  ? disciplines[i].ElectiveDisciplines.filter(function(p) {return p.CommonStatus === ElectiveDisciplineCommonStatusEnum.Active}).map(function(p){ return p.DictDisciplineName }).join(" / ") + " (" + disciplines[i].DictDisciplineName + ")"
                  //вывод дисциплин
                  : disciplines[i].DictDisciplineName),
                disciplineId: disciplines[i].ControllingActions[j].Id,
                discipline:disciplines[i].DictDisciplineName,
                controlActionName: disciplines[i].ControllingActions[j].DictControlActionName,
                semesterNumber: semester
              })
              if(disciplines[i].ControllingActions[j].HasCourseWork)
                this.studentMarks.push({
                  disciplineName: (disciplines[i].ElectiveDisciplines.length > 0
                    //Вывод элективных дисциплин
                    ? disciplines[i].ElectiveDisciplines.filter(function(p) {return p.CommonStatus === ElectiveDisciplineCommonStatusEnum.Active}).map(function(p){ return p.DictDisciplineName }).join(" / ") + " (" + disciplines[i].DictDisciplineName + ")"
                    //вывод дисциплин
                    : disciplines[i].DictDisciplineName),
                  disciplineId: disciplines[i].ControllingActions[j].Id,
                  discipline:disciplines[i].DictDisciplineName,
                  controlActionName: DisciplineWorkTypeEnum.kr,
                  semesterNumber: semester
                })
              if(disciplines[i].ControllingActions[j].HasCourseProject)
                this.studentMarks.push({
                  disciplineName: (disciplines[i].ElectiveDisciplines.length > 0
                    //Вывод элективных дисциплин
                    ? disciplines[i].ElectiveDisciplines.filter(function(p) {return p.CommonStatus === ElectiveDisciplineCommonStatusEnum.Active}).map(function(p){ return p.DictDisciplineName }).join(" / ") + " (" + disciplines[i].DictDisciplineName + ")"
                    //вывод дисциплин
                    : disciplines[i].DictDisciplineName),
                  disciplineId: disciplines[i].ControllingActions[j].Id,
                  discipline:disciplines[i].DictDisciplineName,
                  controlActionName: DisciplineWorkTypeEnum.kp,
                  semesterNumber: semester
                })
            }
            else if (mark.controlActionName === undefined || mark.controlActionName === '') mark.controlActionName = disciplines[i].ControllingActions.find((x: any) => x.SemesterNumber === semester)?.DictControlActionName

            if (markCurrent === undefined)
            {
              this.currentControlMarks.push({
                disciplineName: (disciplines[i].ElectiveDisciplines.length > 0
                  //Вывод элективных дисциплин
                  ? disciplines[i].ElectiveDisciplines.filter(function(p) {return p.CommonStatus === ElectiveDisciplineCommonStatusEnum.Active}).map(function(p){ return p.DictDisciplineName }).join(" / ") + " (" + disciplines[i].DictDisciplineName + ")"
                  //вывод дисциплин
                  : disciplines[i].DictDisciplineName),
                disciplineId: disciplines[i].ControllingActions[j].Id,
                discipline:disciplines[i].DictDisciplineName,
                controlActionName: disciplines[i].ControllingActions[j].DictControlActionSName,
                semesterNumber: semester,
                firstAttestation: null,
                secondAttestation: null
              })
            }
          }
        }
      }
    }
    this.studentMarks = [...this.studentMarks.filter(x => !x.externalId || sessionMarks.includes(x.externalId))]
  }

  public rowClassCallback = (context: RowClassArgs) => {
    const dataLevel = context.dataItem.Level;
    return {
      planLevel0: dataLevel === 0,
      planLevel1: dataLevel === 1,
      planLevel2: dataLevel > 1,
      noActive: context.dataItem.Active == false,
    };
  }

  removeDuplicateSemesters(dataItem: any) {
    let result = [...new Set(dataItem.map((x: any) => x.SemesterNumber))]
      .reduce((res, item: any) => `${res}, ${item.toString()}` +
        `(${dataItem.find((x: any) => x.SemesterNumber === item).DictControlActionAbbreviation})`, '') as string;

    if (result.length > 0) {
      result = result.slice(2)
    }
    return result;
  }

  public buttonClicked = false

  public onSave(e: PointerEvent): void {
    e.preventDefault();
    this.buttonClicked = true
    this.saveStudyPeriod();
  }

  onFocus(){
    let elements = document.querySelectorAll('.k-datepicker input');
    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('wheel', (e) => {
        e.stopImmediatePropagation();
      });
    }
  }

  public saveStudyPeriod() {
    if(this.buttonClicked){
      this.studentMainInfoComponent.contingent.ordersStudyPeriod=this.formStudyPeriod.value;
    }
    if(this.orderStudyPeriod?.studentId == null)
    {
      this.formStudyPeriod.value.studentId = this.studentId;
      this.timeZoneFix();
      this.orderStudyPeriodService.addordersstudyperiod(this.formStudyPeriod?.value)
        .subscribe({
          next:() => {
            if(this.buttonClicked){
              this.notificationService.showSuccess("Период обучения был успешно добавлен!");
              this.buttonClicked = false
              this.getStudentGroupHistory()
            }
          },
          error:() => {
            this.notificationService.showError("Не удалось добавить период обучения");
          }}
        );
    }
    else {
      this.timeZoneFix();
      this.orderStudyPeriodService.updateordersstudyperiod(this.formStudyPeriod?.value)
        .subscribe({
          next:() => {
            if(this.buttonClicked){
              this.notificationService.showSuccess("Период обучения был успешно сохранен!");
              this.buttonClicked = false
              this.getStudentGroupHistory()
            }
          },
          error:() => {
            this.notificationService.showError('Не удалось изменить период обучения');
          }}
        )
    }
  }

  public timeZoneFix()
  {
    if (this.formStudyPeriod.value.dateBegin) this.formStudyPeriod.value.dateBegin = TimeZoneFix(this.formStudyPeriod.value.dateBegin);
    if (this.formStudyPeriod.value.dateEnd) this.formStudyPeriod.value.dateEnd = TimeZoneFix(this.formStudyPeriod.value.dateEnd);
    this.formStudyPeriod.value.dateStart = TimeZoneFix(this.formStudyPeriod.value.dateStart);
    if (this.formStudyPeriod.value.dateFinish != null)
      this.formStudyPeriod.value.dateFinish = TimeZoneFix(this.formStudyPeriod.value.dateFinish);
  }

  getEducationPlanByStudent(){
    this.educationService.getEducationPlanByStudent(this.studentId.toString())
      .subscribe({
        next: (response) => {
          this.educationPlanList = response;
        },
        error: () => {
          this.notificationService.showError('Не удалось получить текущий учебный план');
        }}
      );
  }


  public async getStudentSemesters(dateStart?: Date, dateFinish?: Date){
    (await this.semesterService.getSemesterByStudentId(
      {
        studentId: this.studentId,
        dateStart: dateStart,
        dateFinish: dateFinish
      }))
      .subscribe(
        response => {
          this.studentSemesters = response
          this.semesterData = []
          if(this.studentSemesters != undefined) {
            this.groupingSemesters(this.studentSemesters)
            this.loadItems()
          }
        },
      )
  }

  public getStudentGroupHistory(){
    this.studEduGroupService.getStudentGroupHistory(this.studentId).subscribe(
      response => {
        this.groupHistory = response
        this.historyId = this.groupHistory[0]?.externalId
        this.groupChangeable = this.groupHistory.length > 1
      },
    )
  }

  public loadItems(){
    if (this.allBupData.length > 0) this.getDiscipline()
    const minSemester =  this.studentSemesters.length != 0 ? this.studentSemesters[0].semesterNum : 1
    this.gridViewMark = []
    this.gridViewCurrentMark = []
    this.gridViewMark = groupBy(orderBy(this.studentMarks.filter(x => x.semesterNumber! >= minSemester), this.sort), this.group);
    this.gridViewCurrentMark = groupBy(orderBy(this.currentControlMarks.filter(x => x.semesterNumber! >= minSemester), this.sort), this.group);
  }

  public groupingSemesters(semesters: StudentSemestr[]) {
    let groupedPlanSemesters = groupBy(orderBy(semesters, this.sortSemester), this.groupSemester)
    groupedPlanSemesters.forEach((element: any) => {
      const row = {
        groupName: element.items[0].groupName,
        courseNum: element.items[0].courseNum,
        firstSemesterId: element.items[0].externalId,
        firstSemester: element.items[0].semesterNum,
        firstSemesterStart: DateFromUTCAsLocal(element.items[0].semesterBegin),
        firstSemesterEnd: element.items[0].semesterEnd != null ? DateFromUTCAsLocal(element.items[0].semesterEnd) : null,
        secondSemesterId: element.items.length > 1 ? element.items[1].externalId : null,
        secondSemester: element.items.length > 1 ? element.items[1].semesterNum : null,
        secondSemesterStart: element.items.length > 1 && element.items[1].semesterBegin != null ? DateFromUTCAsLocal(element.items[1].semesterBegin) : null,
        secondSemesterEnd: element.items.length > 1 && element.items[1].semesterEnd != null ? DateFromUTCAsLocal(element.items[1].semesterEnd) : null,
        thirdSemester: element.items.length > 2 ? element.items[2].semesterNum : null,
        thirdSemesterStart: element.items.length > 2 && element.items[2].semesterBegin != null ? DateFromUTCAsLocal(element.items[2].semesterBegin) : null,
        thirdSemesterEnd: element.items.length > 2 && element.items[2].semesterEnd != null ? DateFromUTCAsLocal(element.items[2].semesterEnd) : null,
        closed: element.items[0].closed,
        secondClosed: element.items.length > 1 ? element.items[1].closed : false,
      }
      this.semesterData.push(row)
    })
  }

  getStudPlan(){
    this.studPlanService.getStudPlanByStudentId(this.studentId)
      .subscribe({
        next: (response) => {
          this.studPlan = response;
          this.planId = this.studPlan.planId
        },
        error: () => {
          this.notificationService.showError('Не удалось получить план студента');
        }}
      );
  }

  public termsOpen(){
    this.termsEdit = true
  }

  public termsClose(){
    this.semesterData = []
    if(this.studentSemesters != undefined) this.groupingSemesters(this.studentSemesters)
    this.termsEdit = false
  }

  public opened = false;

  public openDialog(): void {
    this.opened = true;
  }

  public closeDialog(): void {
    this.opened = false;
  }

  public onSaveSemesters(){
    this.semestersToSave = []
    this.addSemesterToSave()
    this.saveSemesters()
  }

  public saveSemesters(){
    this.semesterService.editSemesterList(this.semestersToSave)
      .subscribe({
        next:() => {
          this.getStudentSemesters();
          this.notificationService.showSuccess("Сохранено");
          this.termsEdit = false
        },
        error: (error) => {
          this.notificationService.showError(error.error);
        }}
      );
  }

  public addSemesterToSave(){
    let semesters: any[] = []
    if(this.semesterData!= undefined){
      this.semesterData.forEach((el) => {
        semesters.push({
          externalId: el.firstSemesterId,
          studentId: this.studentId,
          groupName: el.groupName,
          semestrNum: el.firstSemester,
          courseNum: el.courseNum,
          semestrBegin: el.firstSemesterStart != null ? TimeZoneFix(el.firstSemesterStart): null,
          semestrEnd: el.firstSemesterEnd != null ? TimeZoneFix(el.firstSemesterEnd): null,
        })
        if(el.secondSemester!=null)
          semesters.push({
            externalId: el.secondSemesterId,
            studentId: this.studentId,
            groupName: el.groupName,
            semestrNum: el.secondSemester,
            courseNum: el.courseNum,
            semestrBegin: el.secondSemesterStart != null ? TimeZoneFix(el.secondSemesterStart): null,
            semestrEnd: el.secondSemesterEnd != null ? TimeZoneFix(el.secondSemesterEnd): null,
          })
      })
    }
    this.semestersToSave = semesters
  }

  public get semesterIncorrect(){
    return this.semesterData.find((x) => x.firstSemesterStart == undefined || x.secondSemesterStart == undefined) != undefined;
  }

  setSemesterFromPlan(){
    this.semestersToSave = []
    this.addSemesterToSave()

    let request = {
      planId : this.planId,
      studentId: this.studentId,
      dto: this.semestersToSave
    }

    this.semesterService.setSemesterFromCurrentPlan(request)
      .subscribe({
        next:() => {
          this.getStudentSemesters();
          this.notificationService.showSuccess("Сохранено");
        },
        error: (error) => {
          this.notificationService.showError(error.error);
        }}
      );
    this.opened = false;
  }

  async onGroupChange(studPlanId: any){
    this.allBupData = []
    const group = this.groupHistory.find((x) => x.externalId == studPlanId);

    this.currentGroup = this.groupHistory[0].externalId == group?.externalId

    this.studentSemesters = []
    if(this.currentGroup){
      await this.getStudentSemesters()
      await this.getEducationPlanDiscipline()
    }
    else {
      await this.getStudentSemesters(group?.dateStart, group?.dateFinish)
      await this.getEducationPlanJsonData(group?.planId)
    }

    const requestMiddle = {
      studentId: this.studentId,
      planId: group?.groupPlanId,
    };
    let request = {
      studentId: this.studentId,
      dateStart: group?.planStart,
      dateFinish: group?.planFinish
    }
    await this.getCurrentControl(request)
    await this.getAcademicProgress(requestMiddle)
  }

  async getEducationPlanJsonData(planId?: Guid) {
    if(planId)
      (await this.educationService.getPlanJsonData(planId))
        .subscribe({
          next: (response) => {
            //this.educationPlan = response;
            this.planJsonDataProcess(response?.jsonData)
          },
          error: () => {
            this.notificationService.showError('Не удалось получить текущий учебный план');
          }}
        );
  }

  async getEducationPlanDiscipline() {
    (await this.studPlanService.getEducationPlanDiscipline(this.studentId))
      .subscribe({
        next: (response) => {
          this.educationPlan = response;
          this.planJsonDataProcess(this.educationPlan?.jsonData)
        },
        error: () => {
          this.notificationService.showError('Не удалось получить текущий учебный план');
        }}
      );
  }

  public planJsonDataProcess(jsonData: any){
    let arr = JSON.parse(jsonData);
    let planData = [];
    let currentCycleId = '', currentComponent:any;

    this.planName = arr.Name
    for(let discipline of arr.Disciplines)
    {
      if(currentCycleId!=discipline.DictCycleId) {
        planData.push({
          Id: null,
          Cipher: discipline.DictCycleSName,
          DictDisciplineName: discipline.DictCycleName,
          DepartmentName: '',
          ControllingActions: [],
          TotalNumberHours: sumHours(arr.Disciplines, discipline.DictCycleId, null),
          TotalLaborIntensity: sumIntensity(arr.Disciplines, discipline.DictCycleId, null),
          Lectures: sumHoursControlType(arr.Disciplines, this.type.lek, discipline.DictCycleId, null),
          Laboratory: sumHoursControlType(arr.Disciplines, this.type.lab, discipline.DictCycleId, null),
          Practice: sumHoursControlType(arr.Disciplines, this.type.pract, discipline.DictCycleId, null),
          KSR: sumHoursControlType(arr.Disciplines, this.type.csr, discipline.DictCycleId, null),
          Control: sumHoursControlType(arr.Disciplines, this.type.control, discipline.DictCycleId, null),
          HoursCredited: sumHoursControlType(arr.Disciplines, this.type.credit, discipline.DictCycleId, null),
          HoursSelfWork: sumHoursControlType(arr.Disciplines, this.type.self, discipline.DictCycleId, null),
          Level: 0
        });
        currentCycleId = discipline.DictCycleId;
      }
      if(currentComponent == undefined || currentComponent.Id != discipline.DictComponent.Id) {
        if((currentComponent == undefined || currentComponent.ParentDictComponent?.Id != discipline.DictComponent.ParentDictComponent?.Id)
          && discipline.DictComponent.ParentDictComponent != null)
          planData.push({
            Active: discipline.CommonStatus === this.commonStatus.Active,
            Id: null,
            Cipher: !discipline.DictComponent.ParentDictComponent.HiddenCipher
              ? discipline.DictCycleSName + '.' + discipline.DictComponent.ParentDictComponent.SName
              : '',
            DictDisciplineName: discipline.DictComponent.ParentDictComponent.Name,
            DepartmentName: '',
            ControllingActions: [],
            TotalNumberHours: sumHours(arr.Disciplines, discipline.DictCycleId, discipline.DictComponent.ParentDictComponent.Id),
            TotalLaborIntensity: sumIntensity(arr.Disciplines, discipline.DictCycleId, discipline.DictComponent.ParentDictComponent.Id),
            Lectures: sumHoursControlType(arr.Disciplines, this.type.lek, discipline.DictCycleId, discipline.DictComponent.ParentDictComponent.Id),
            Laboratory: sumHoursControlType(arr.Disciplines, this.type.lab, discipline.DictCycleId, discipline.DictComponent.ParentDictComponent.Id),
            Practice: sumHoursControlType(arr.Disciplines, this.type.pract, discipline.DictCycleId, discipline.DictComponent.ParentDictComponent.Id),
            KSR: sumHoursControlType(arr.Disciplines, this.type.csr, discipline.DictCycleId, discipline.DictComponent.ParentDictComponent.Id),
            Control: sumHoursControlType(arr.Disciplines, this.type.control, discipline.DictCycleId, discipline.DictComponent.ParentDictComponent.Id),
            HoursCredited: sumHoursControlType(arr.Disciplines, this.type.credit, discipline.DictCycleId, discipline.DictComponent.ParentDictComponent.Id),
            HoursSelfWork: sumHoursControlType(arr.Disciplines, this.type.self, discipline.DictCycleId, discipline.DictComponent.ParentDictComponent.Id),
            Level: 1
          });
        if(!discipline.DictComponent.HiddenTitle)
          planData.push({
            Active: discipline.CommonStatus === this.commonStatus.Active,
            Id: null,
            Cipher: !discipline.DictComponent.HiddenCipher
              ? discipline.DictCycleSName + '.' + discipline.DictComponent.SName
              : '',
            DictDisciplineName: discipline.DictComponent.Name,
            DepartmentName: '',
            ControllingActions: [],
            TotalNumberHours: sumHours(arr.Disciplines, discipline.DictCycleId, discipline.DictComponent.Id),
            TotalLaborIntensity: sumIntensity(arr.Disciplines, discipline.DictCycleId, discipline.DictComponent.Id),
            Lectures: sumHoursControlType(arr.Disciplines, this.type.lek, discipline.DictCycleId, discipline.DictComponent.Id),
            Laboratory: sumHoursControlType(arr.Disciplines, this.type.lab, discipline.DictCycleId, discipline.DictComponent.Id),
            Practice: sumHoursControlType(arr.Disciplines, this.type.pract, discipline.DictCycleId, discipline.DictComponent.Id),
            KSR: sumHoursControlType(arr.Disciplines, this.type.csr, discipline.DictCycleId, discipline.DictComponent.Id),
            Control: sumHoursControlType(arr.Disciplines, this.type.control, discipline.DictCycleId, discipline.DictComponent.Id),
            HoursCredited: sumHoursControlType(arr.Disciplines, this.type.credit, discipline.DictCycleId, discipline.DictComponent.Id),
            HoursSelfWork: sumHoursControlType(arr.Disciplines, this.type.self, discipline.DictCycleId, discipline.DictComponent.Id),
            Level: 1
          });
        currentComponent = discipline.DictComponent;
      }
      planData.push({
        Active: discipline.CommonStatus === this.commonStatus.Active,
        Id: discipline.Id,
        Cipher: discipline.Cipher,
        DictDisciplineName: discipline.DictDisciplineName,
        DepartmentName: discipline.DepartmentSName,
        ControllingActions: discipline.ControllingActions,
        TotalNumberHours: discipline.TotalNumberHours,
        TotalLaborIntensity: discipline.TotalLaborIntensity,
        Lectures: calculateHoursCount(discipline.ControllingActions, this.type.lek),
        Laboratory: calculateHoursCount(discipline.ControllingActions, this.type.lab),
        Practice: calculateHoursCount(discipline.ControllingActions, this.type.pract),
        KSR: calculateHoursCount(discipline.ControllingActions, this.type.csr),
        Control: calculateHoursCount(discipline.ControllingActions, this.type.control),
        HoursCredited: calculateHoursCount(discipline.ControllingActions, this.type.credit),
        HoursSelfWork: calculateHoursCount(discipline.ControllingActions, this.type.self),
        Level: 2
      });
      for(let el = 0; el < discipline.ElectiveDisciplines.length; el++) {
        planData.push({
          Active: discipline.ElectiveDisciplines[el].CommonStatus === this.commonStatus.Active,
          Id: discipline.Id,
          Cipher: '',
          DictDisciplineName: discipline.ElectiveDisciplines[el].DictDisciplineName,
          DepartmentName: discipline.ElectiveDisciplines[el].DepartmentSName,
          ControllingActions: [],
          TotalNumberHours: 0,
          TotalLaborIntensity: 0,
          Control: 0,
          HoursCredited: 0,
          HoursSelfWork: 0,
          Level: 3
        });
      }
    }


    this.allBupData = arr.Disciplines.slice();
    this.loadItems()
    this.bupData = arr.Disciplines;

    this.gridData = planData;

    let semesterNumbers = [...new Set(arr.Semesters.map((item:any) => item.Number))] as number[];
    this.totalColumns = [...semesterNumbers.map((item:number) => ({field: '_'+item, title: item}))];
    this.totalData = [];

    let tmp = {name: "Аудиторная нагрузка, ак.ч."};
    for (let col of this.totalColumns) {
      Object.defineProperty(tmp, col.field, { value: sumContactWork(arr.Disciplines, col.title) });
    }
    this.totalData.push(tmp);

    let actionsArr = arr.Disciplines.flatMap((item:any) => item.ControllingActions);
    let actions = [...new Map(actionsArr
      .map((item:any) => ([item.DictControlActionName, {id: item.DictControlActionId, name: item.DictControlActionName}])))
      .values()].sort((a:any, b:any) => a.name.localeCompare(b.name)) as any[];
    for(let action of actions) {
      tmp = {name: action.name + ', шт.'};
      for (let col of this.totalColumns) {
        Object.defineProperty(tmp, col.field, { value: countActions(actionsArr, col.title, action.id, null, null) });
      }
      this.totalData.push(tmp);
    }
    let cnt = 0;
    tmp = {name: "Курсовая работа, шт."};
    for (let col of this.totalColumns) {
      let tmpRes = countActions(actionsArr, col.title, null, true, false);
      cnt += tmpRes;
      Object.defineProperty(tmp, col.field, { value: tmpRes });
    }
    //если вся строка нулевая - скроем
    if(cnt>0)
      this.totalData.push(tmp);
    cnt = 0;
    tmp = {name: "Курсовой проект, шт."};
    for (let col of this.totalColumns) {
      let tmpRes = countActions(actionsArr, col.title, null, true, false)
      cnt += tmpRes;
      Object.defineProperty(tmp, col.field, { value: tmpRes });
    }
    //если вся строка нулевая - скроем
    if(cnt>0)
      this.totalData.push(tmp);
  }

  public result(id: number): string {
    let mark = this.studentMarks.find((x) => x.semesterNumber === id);
    //ToDo резулльтат сессии
    if(mark?.semester!=null) return " (" + mark.semester + ")"
    else return ""
  }

  onChangePlan(){
    let plan: any = {
      planId: this.planId,
    }
    localStorage.setItem('plan', JSON.stringify(plan));
    this.router.navigateByUrl(`/contingent/studplan/${this.studentId}` , { state: { planId: this.planId } }).then();
  }

  definitionControlAction(dataItem: any)
  {
    //выводим тип контрольной акции
    let string: string;
    string = dataItem.find((x: any)=>x.SemesterNumber===this.educationPlan.semesterNum)?.DictControlActionName
    return string;
  }

  public formStudyPeriod: UntypedFormGroup = new UntypedFormGroup({
    ordersStudyPeriodId: new UntypedFormControl(),
    ordersStudyPeriodExternalId: new UntypedFormControl(),
    studentId: new UntypedFormControl(),
    student: new UntypedFormControl(),
    dateBegin:  new UntypedFormControl(),
    dateEnd:  new UntypedFormControl(),
    dateStart: new UntypedFormControl(),
    dateFinish: new UntypedFormControl(),
  });
}

function sumHours(disciplines: [], cycleId:string|null, componentId:string|null): number {
  return disciplines.filter((item: any) =>
    !item.DictComponent.HiddenLaborIntensity &&
    (cycleId == null || item.DictCycleId == cycleId) &&
    (componentId == null || item.DictComponent.Id == componentId || item.DictComponent.ParentDictComponent?.Id == componentId)
  ).reduce((sum, current:any) => sum + current.TotalNumberHours, 0);
}

function sumHoursControlType(disciplines: [], type: string, cycleId:string|null, componentId:string|null): number {
  return disciplines.filter((item: any) =>
    !item.DictComponent.HiddenLaborIntensity &&
    (cycleId == null || item.DictCycleId == cycleId) &&
    (componentId == null || item.DictComponent.Id == componentId || item.DictComponent.ParentDictComponent?.Id == componentId)
  ).reduce((sum, current:any) => sum + calculateHoursCount(current.ControllingActions, type), 0);
}

function calculateHoursCount(dataItem: any, type: string) {
  let result = 0
  if(dataItem !== undefined && dataItem.length > 0)
    switch (type){
      case DisciplineWorkTypeEnum.control:
        dataItem.forEach((element: any) => result = result + element.Control)
        break;
      case DisciplineWorkTypeEnum.credit:
        dataItem.forEach((element: any) => result = result + element.HoursCredited)
        break;
      case DisciplineWorkTypeEnum.self:
        dataItem.forEach((element: any) => result = result + element.HoursSelfWork)
        break;
      default:
        dataItem.forEach((element: any) => result = result + element.TypeWorks.find((x: any) => x.SName === type)?.Value)
        break;
    }
  return result;
}

function sumIntensity(disciplines: [], cycleId:string|null, componentId:string|null): number {
  return disciplines.filter((item: any) =>
    !item.DictComponent.HiddenLaborIntensity &&
    (cycleId == null || item.DictCycleId == cycleId) &&
    (componentId == null || item.DictComponent.Id == componentId || item.DictComponent.ParentDictComponent?.Id == componentId)
  ).reduce((sum, current:any) => sum + current.TotalLaborIntensity, 0);
}

function sumContactWork(disciplines: [], semester:number|null): number {
  let dataArr = disciplines.filter((item: any) => !item.DictComponent.HiddenLaborIntensity)
    .flatMap((item:any) => item.ControllingActions)
    .filter((item: any) => semester == null || item.SemesterNumber == semester);
  return dataArr.reduce((sum, item:any) => sum + item.HoursContactWork, 0);
}

function countActions(actions: [], semester:number, controlActionId:string|null, isCourseWork:boolean|null, isCourseProject:boolean|null): number {
  return actions.filter((item: any) =>
    item.SemesterNumber == semester &&
    (controlActionId==null || item.DictControlActionId == controlActionId) &&
    (isCourseProject==null || item.HasCourseProject==isCourseProject) &&
    (isCourseWork==null || item.HasCourseWork==isCourseWork)
  ).length;
}
